import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ColorExample from '../../../../components/examples/ColorExample';
import ExternalLink from '../../../../components/ExternalLink';
import InternalLink from '../../../../components/InternalLink';
import TabsLayout from './tabs.mdx';
export const _frontmatter = {
  "slug": "/foundation/design-tokens/colour",
  "title": "Colour tokens - Usage",
  "navTitle": "Colour"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ({
  children,
  pageContext
}) => <TabsLayout pageContext={pageContext}>{children}</TabsLayout>;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h2" {...{
        "href": "#usage",
        "aria-label": "usage permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h2>
    <p>{`Colour can be used for branding, providing visual hierarchy and giving visual cues for action.`}</p>
    <p>{`The Helsinki Design system colour tokens are based on the City of Helsinki brand colour palette. More information on using and combining the brand colours in the `}<ExternalLink href="https://brand.hel.fi/en/colours/" mdxType="ExternalLink">{`City of Helsinki Visual Identity Guidelines - Colours`}</ExternalLink>{`.`}</p>
    <p>{`Also see visual "Do and Don't" example images in the `}<ExternalLink href="https://share.goabstract.com/30c40134-681b-4f96-9dfc-ee6d32c2f1eb" mdxType="ExternalLink">{`Colour usage examples - Abstract collection`}</ExternalLink>{`. These images will help you understand where brand colours and their variants can be used.`}</p>
    <h3 {...{
      "id": "principles",
      "style": {
        "position": "relative"
      }
    }}>{`Principles`}<a parentName="h3" {...{
        "href": "#principles",
        "aria-label": "principles permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Use bold colours to attract users attention`}</strong>{` to most important content and primary actions.`}</li>
      <li parentName="ul"><strong parentName="li">{`Use lighter hues and shades to fade less important information to the background`}</strong>{` but be sure to maintain an accessible colour contrast.`}</li>
      <li parentName="ul">{`Colour can also be used to differentiate sections or content types.`}</li>
    </ul>
    <h3 {...{
      "id": "usage-1",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}<a parentName="h3" {...{
        "href": "#usage-1",
        "aria-label": "usage 1 permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <p>{`The design system uses a colour theme that includes:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Primary colour:`}</strong>{` the colour displayed most frequently across your service and its components. If you are not using a secondary colour, your primary colour can also be used to accent elements.`}</li>
      <li parentName="ul"><strong parentName="li">{`Secondary and Tertiary colours:`}</strong>{` these provide more ways for accenting select UI elements and distinguishing your service. The secondary and tertiary colours should be applied sparingly and with intent.`}</li>
      <li parentName="ul"><strong parentName="li">{`UI colours:`}</strong>{` the common colours for feedback (error, warning, success, info).`}</li>
      <li parentName="ul"><strong parentName="li">{`Grayscale:`}</strong>{` neutral colour shades.`}</li>
    </ul>
    <p>{`The colour palette also provides lighter and darker variants for the base colours.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Do not use light and dark variants as the main colour of a page.`}</strong></li>
      <li parentName="ul">{`Light and dark variants should only be used in addition to base colours for theming UI component states and differentiating information types.`}</li>
    </ul>
    <h4 {...{
      "id": "customising-colours",
      "style": {
        "position": "relative"
      }
    }}>{`Customising colours`}<a parentName="h4" {...{
        "href": "#customising-colours",
        "aria-label": "customising colours permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Colours are defined as `}<ExternalLink href="https://developer.mozilla.org/en-US/docs/Web/CSS/var" mdxType="ExternalLink">{`CSS variables`}</ExternalLink>{` and the theme is therefore customisable.`}</p>
    <p><strong parentName="p">{`When customising colours always check that:`}</strong></p>
    <ol>
      <li parentName="ol">{`the colour combination follows the `}<ExternalLink href="https://brand.hel.fi/en/colours/#safe-color-combinations" mdxType="ExternalLink">{`City of Helsinki Visual Identity Guidelines - Safe color combinations`}</ExternalLink></li>
      <li parentName="ol">{`the colour contrast is compliant with `}<ExternalLink href="https://www.w3.org/TR/WCAG21/#contrast-minimum" mdxType="ExternalLink">{`WCAG 2.1 Standard at AA Level contrast ratios`}</ExternalLink>{`. Note that font size affects the contrast criteria of text elements. You can find tools for checking contrast accessibility in the `}<InternalLink size="M" href="/about/resources" mdxType="InternalLink">{` Resources page`}</InternalLink></li>
    </ol>
    <h3 {...{
      "id": "hds-colours",
      "style": {
        "position": "relative"
      }
    }}>{`HDS colours`}<a parentName="h3" {...{
        "href": "#hds-colours",
        "aria-label": "hds colours permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h3>
    <h4 {...{
      "id": "brand-colours",
      "style": {
        "position": "relative"
      }
    }}>{`Brand colours`}<a parentName="h4" {...{
        "href": "#brand-colours",
        "aria-label": "brand colours permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Brand colours are used for primary, secondary and tertiary colours. They should not be used for indicating system status.`}</p>
    <p>{`Brand colours also have dark, medium light and light variants. You can find these tokens on the Tokens tab. Before using Dark and Light brand colour variants, please see example images in the `}<ExternalLink href="https://share.goabstract.com/30c40134-681b-4f96-9dfc-ee6d32c2f1eb" mdxType="ExternalLink">{`Colour usage examples - Abstract collection`}</ExternalLink>{`.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Colour name (en)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Colour name (fi)`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bussi`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-bus)" name="Bus brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Coat of Arms`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Vaakuna`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-coat-of-arms)" name="Coat of Arms brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Gold`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kulta`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-gold)" name="Gold brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Silver`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hopea`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-silver)" name="Silver brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Brick`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tiili`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-brick)" name="Brick brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Copper`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kupari`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-copper)" name="Copper brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Engel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Engel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-engel)" name="Engel brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Fog`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Sumu`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-fog)" name="Fog brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Metro`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Metro`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-metro)" name="Metro brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Summer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kesä`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-summer)" name="Kesä brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Suomenlinna`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Suomenlinna`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-suomenlinna)" name="Suomenlinna brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Tram`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Spåra`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-tram)" name="Tram brand colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 1:Brand colours]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "ui-colours",
      "style": {
        "position": "relative"
      }
    }}>{`UI colours`}<a parentName="h4" {...{
        "href": "#ui-colours",
        "aria-label": "ui colours permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Status colours are reserved exclusively for indicating valid, invalid, or informational states on the service.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Status name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Usage`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example message`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Example`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Info`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`State which does not indicate clear success or failure but can be useful extra information to the user.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`"You have received new messages."`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-info)" name="Info status colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Success`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Positive state to inform user about success.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`"Form submit was successful!"`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-success)" name="Success status colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Alert`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Warning state to catch users attention but does not require immediate actions.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`"Loading is taking longer than expected."`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-alert)" name="Alert status colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Error`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Error state which requires users immediate action.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`"Form is missing critical information."`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-error)" name="Error status colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 2:Status colours]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "grayscale",
      "style": {
        "position": "relative"
      }
    }}>{`Grayscale`}<a parentName="h4" {...{
        "href": "#grayscale",
        "aria-label": "grayscale permalink",
        "className": "header-anchor after"
      }}><span parentName="a" {...{
          "className": "hds-anchor-icon hds-icon hds-icon--link hds-icon--size-xs",
          "aria-hidden": "true",
          "style": {
            "verticalAlign": "middle"
          }
        }}></span></a></h4>
    <p>{`Grayscale colours are neutral shades that can be used for all user interface purposes. Be mindful of the colour contrast especially on lighter shades of gray. Check the Accessibility tab for more information about grayscale contrast.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Black`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`90%`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`80%`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`70%`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`60%`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`50% `}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`40%`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`30%`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`20%`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`10%`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`5%`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`White`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black)" name="Black colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black-90)" name="Black-90 colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black-80)" name="Black-80 colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black-70)" name="Black-70 colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black-60)" name="Black-60 colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black-50)" name="Blac-50 colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black-40)" name="Black-40 colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black-30)" name="Black-30 colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black-20)" name="Black-20 colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black-10)" name="Black-10 colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-black-5)" name="Black-5 colour example" mdxType="ColorExample" /></td>
          <td parentName="tr" {...{
            "align": null
          }}><ColorExample color="var(--color-white)" name="White colour example" mdxType="ColorExample" /></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`[Table 3:Grayscale]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      